<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="pa-0">
        <v-stepper v-if="usingStepper" v-model="step"> </v-stepper>
        <v-row v-else>
          <v-col id="tabs">
            <v-tabs id="customTab" v-model="step" background-color="transparent">
              <v-tab class="text-capitalize">Soal</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-tabs-items class="transparent" v-model="step">        
          <v-tab-item class="rounded-lg">
            <v-card elevation="0" style="background: transparent;" class="pl-1 pr-3 rounded-lg">
              <v-card-text class="pa-1">
                <v-row v-if="$route.name == 'epot-index'">
                  <!-- <div class="col-md-12"> -->
                    <v-expansion-panels id="panels">
                      <v-col
                        cols="12"
                        class="pl-1 pa-0"
                        v-for="(soal, index) in soal"
                        :key="index"
                      >
                        <v-expansion-panel
                          cols="12"
                          class="pa-0 my-2 rounded-lg box-soal"
                          v-if="soal.questions != undefined"
                        >
                          <v-expansion-panel-header>
                            <div class="color-navy title">
                              Group {{ index + 1 }}
                            </div>
                            <v-spacer></v-spacer>
                            <div class="text-right">
                              <v-icon
                                class="ml-2 color-navy"
                                @click="dialog.hapusQuestion = true"
                              >
                                $delete
                              </v-icon>
                            </div>
                            <template v-slot:actions>
                              <v-btn icon class="ml-2">
                                <!-- <img src="../../assets/images/expand.png" alt="" /> -->
                                <v-icon>$expand</v-icon>
                              </v-btn>
                            </template>
                            <v-dialog
                            v-model="dialog.hapusQuestion"
                            persistent
                            max-width="600px"
                          >
                            <v-card>
                              <v-card-title>
                                <span class="headline color-navy"
                                  ><v-icon class="color-navy"
                                    >mdi-alert-circle</v-icon
                                  >
                                  Hapus Pertanyaan</span
                                >
                              </v-card-title>
                              <v-divider></v-divider>
                              <div>
                                <v-card-text>
                                  <p class="color-navy">
                                    Apakah anda ingin menghapus Group pertanyaan ini ?
                                  </p>
                                </v-card-text>
                              </div>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  large
                                  @click="dialog.hapusQuestion = false"
                                >
                                  Batal
                                </v-btn>
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  large
                                  @click="remove(index)"
                                >
                                  Hapus
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-row>
                              <div class="col-md-6">
                                <p class="color-navy mb-2 ">Group instruction</p>
                                <v-textarea
                                  outlined
                                  v-model="instruksi"
                                  filled
                                  color="primary"
                                  placeholder="Write the instruction"
                                >
                                </v-textarea>
                              </div>
                              <div class="col-md-6">
                                <div class="action">
                                  <v-spacer></v-spacer>
                                  <p class="color-navy mb-2">Attachment</p>
                                  <image-attachment
                                    class="mb-2"
                                    :src="img"
                                    @set-attachment="setImageAttachment"
                                  />
                                  <audio-attachment
                                    class="mr-2"
                                    :src="audioGrup"
                                    @set-attachment="setAudioAttachment3"
                                  />              
                                </div>
                              </div>
                              <v-col cols="12" md="12">
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-card elevation="0" style="background: transparent;" v-for="(soal, index2) in soal.questions" :key="index2" class="my-1">
                                  <v-card-title>
                                    <h5 class="color-navy title">
                                      Nomor {{index + 1}}.{{ index2 + 1 }}
                                    </h5>
                                    <v-spacer></v-spacer>
                                    <div class="text-right">
                                      <v-icon
                                        class="ml-2 color-navy"
                                        @click="dialog.hapusQuestionGroup = true"
                                      >
                                        $delete
                                      </v-icon>
                                    </div>
                                  </v-card-title>
                                  <v-card-text>
                                    <inputSoal />
                                  </v-card-text>
                                  <v-divider></v-divider>
                                  <v-dialog
                            v-model="dialog.hapusQuestionGroup"
                            persistent
                            max-width="600px"
                          >
                            <v-card>
                              <v-card-title>
                                <span class="headline color-navy"
                                  ><v-icon class="color-navy"
                                    >mdi-alert-circle</v-icon
                                  >
                                  Hapus Pertanyaan</span
                                >
                              </v-card-title>
                              <v-divider></v-divider>
                              <div>
                                <v-card-text>
                                  <p class="color-navy">
                                    Apakah anda ingin menghapus pertanyaan group  ini ?
                                  </p>
                                </v-card-text>
                              </div>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  large
                                  @click="dialog.hapusQuestionGroup = false"
                                >
                                  Batal
                                </v-btn>
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  large
                                  @click="removeGroup(index,index2)"
                                >
                                  Hapus
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                                </v-card>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="accent-primary"
                                      elevation="0"
                                    >
                                      Tambah soal ke group
                                      <v-icon class="ml-1">$arrdown</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item @click="addGroupQuestion(index)">
                                      <v-list-item-title>Buat Baru</v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item :to="{name: 'import-question-groups'}">
                                      <v-list-item-title
                                        >Ambil dari bank soal</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>

                        </v-expansion-panel>
                        <v-expansion-panel v-else class="my-2 rounded-lg box-soal">
                          <v-expansion-panel-header>
                            <div class="color-navy title">
                              Nomor {{ index + 1 }}
                            </div>
                            <v-spacer></v-spacer>
                            <div class="text-right">
                              <v-icon
                                
                                class="ml-2 color-navy"
                                @click="dialog.hapusQuestion = true"
                              >
                                $delete
                              </v-icon>
                            </div>
                            <template v-slot:actions>
                              <v-btn icon class="ml-2">
                                <!-- <img src="../../assets/images/expand.png" alt="" /> -->
                                <v-icon>$expand</v-icon>
                              </v-btn>
                            </template>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <inputSoal :inputData.sync="parentData" :inputAnswer.sync="optionAnswer" :inputCorrectAnswer.sync="correct" />
                          </v-expansion-panel-content>

                          <v-dialog
                            v-model="dialog.hapusQuestion"
                            persistent
                            max-width="600px"
                          >
                            <v-card>
                              <v-card-title>
                                <span class="headline color-navy"
                                  ><v-icon class="color-navy"
                                    >mdi-alert-circle</v-icon
                                  >
                                  Hapus Pertanyaan</span
                                >
                              </v-card-title>
                              <v-divider></v-divider>
                              <div>
                                <v-card-text>
                                  <p class="color-navy">
                                    Apakah anda ingin menghapus pertanyaan ini ?
                                  </p>
                                </v-card-text>
                              </div>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  large
                                  @click="dialog.hapusQuestion = false"
                                >
                                  Batal
                                </v-btn>
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  large
                                  @click="remove(index)"
                                >
                                  Hapus
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          
                        </v-expansion-panel>
                      </v-col>
                    </v-expansion-panels>
                    <!-- <inputSoal v-for="(soal, index) in soal" :key="index"/> -->
                  <!-- </div> -->
                  <v-divider></v-divider>
                  <!-- <v-row> -->
                    <v-col cols="12" md="6" class="pl-1">
                      <div class="d-flex">
                      <div class="mr-2">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="btn-white text-capitalize"
                              elevation="0"
                            >
                              Tambah Soal
                              <v-icon class="ml-1">$arrdown</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="addQuestion">
                              <v-list-item-title>Buat Baru</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :to="{name: 'import-questions'}">
                              <v-list-item-title
                                >Ambil dari bank soal</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <div id="" class="">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="btn-white text-capitalize"
                              elevation="0"
                            >
                              Tambah Group Soal<v-icon
                                class="ml-1"
                                >$arrdown</v-icon
                              >
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="addGroup">
                              <v-list-item-title>Buat Baru</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :to="{name: 'import-question-groups'}">
                              <v-list-item-title
                                >Ambil dari bank soal</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="text-right" v-show="btnSave">
                        <v-spacer></v-spacer>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="white--text text-capitalize"
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                            >
                              Submit <v-icon class="ml-2">$arrdown</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="save()">
                              <v-list-item-title>Simpan dan Publish</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>Simpan di draft</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-col>
                  <!-- </v-row> -->
                </v-row>
                <v-row v-else>
                  <v-col class="px-1 py-0">
                  <router-view @import-questions="add"></router-view>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "vue-md-player/dist/vue-md-player.css";
import inputSoal from "./components/EpotInputSoal";
// import { audioPlayer } from 'vue-md-player'
import AudioAttachmentModal from "@/views/_inc/modal/AudioAttachment";
import ImageAttachmentModal from "@/views/_inc/modal/ImageAttachment";

export default {
  name:'epot-createx',
  components: {
    // ValidationProvider,
    // audioPlayer,
    inputSoal,
    "audio-attachment": AudioAttachmentModal,
    "image-attachment": ImageAttachmentModal,
  },
  data: () => ({
    usingStepper: false,
    step: null,
    tabs: null,
    btnSave:false,
    dialog: {
      hapusQuestion: false,
      hapusQuestionGroup: false,
    },
    gambar: "",
    nama: "",
    tujuan: "",
    instruksi: "",
    result: "",
    timers: ["Ya", "Tidak"],
    section: null,
    part: null,
    sections: [
      { id: 2, name: "Listening" },
      { id: 3, name: "Reading" },
      { id: 1, name: "Structure" },
    ],
    parts: [
      { id: 1, name: "Part A", section_id: 1 },
      { id: 2, name: "Part B", section_id: 1 },
      { id: 3, name: "Part A", section_id: 2 },
      { id: 4, name: "Part B", section_id: 2 },
      { id: 5, name: "Part C", section_id: 2 },
      { id: 6, name: "Tanpa Part", section_id: 3 },
    ],
    content: {},
    soal:[{
      
    }],
    setting: {
      nama:'',
      section:'',
      part:'',
      ranques:'',
      direction:'',
      statement:'',
    },
    submitting: false,
    audio: null,
    audio2: null,
    audioGrup: null,
    img: null,
    parentData:'',
    correct:[],
    optionAnswer:[]
  }),
  methods: {
    setAudioAttachment(src) {
      this.audio = src;
    },
    setAudioAttachment2(src) {
      this.audio2 = src;
    },
    setImageAttachment(src) {
      this.img = src;
    },
    setAudioAttachment3(src) {
      this.audioGrup = src;
    },
    onChangeChain() {
      this.part = -1;
      if (!this.section) {
        this.section = -1;
      }
    },
    save() {
      // let formData = Object.assign({}, this.form);
      let formData = this.soal;

      // console.log('options',this.optionAnswer);
      // console.log('isi form',formData);
      this.axios
          .post("https://34.101.181.128:8000/bank/v1/question/", formData
            // {
            //   headers: {
            //     Authorization: "Basic dGVzdGluZzoxMjM=",
            //     "x-api-key": "4e10956ba06d926751038851124acdce"
            //   }
            // }
          )
          .then(response => {
            let res = response.data;
            if (res.status == 200) {
              // this.$router.push({ name: "Project" });
            } else {
              this.process.run = false;
              this.response = res.msg;
            }
          });
    },
    addQuestion() {
      this.soal.push({
        type : 'single_choice ',
        instruction_text : '',
        instruction_media:'',
        question_text :this.parentData,
        options : this.optionAnswer ,
        correct_answer :this.correct
      });
      this.btnSave=true;
    },
    remove(index) {
      this.soal.splice(index, 1);
      this.dialog.hapusQuestion = false;
      this.btnSave=false;
    },
    removeGroup(index,index2) {
      this.soal[index].splice(index2,1);
      this.dialog.hapusQuestionGroup = false;
      this.btnSave=false;
    },
    addGroup() {
      this.soal.push({
        questions: [{}]
      });
    },
    addGroupQuestion(group) {
      this.soal[group].push({});
      this.btnSave=true;
    },
    add(questions, group = null) {
      if (group != null) {
        this.soal[group].push(questions);
      } else {
        this.soal.push(questions);
      }
    },
  },
  // created() {
  //  this.$root.$emit("setPageTitle", "New Tes");
  // },
  computed: {
    isDetailFilled() {
      let status = true;
      this._.each(this.setting, (val, key) => {
        if (["nama", "section","part", "ranques","direction"].find((el) => el == key)) return;
        status = this._.isEmpty(val) ? false : status;
      });
      return status;
    },
    partnya() {
      let partku = [];
      for (let i = 0; i < this.parts.length; i++) {
        let parts = this.parts[i];
        if (parts.section_id == this.section) {
          partku.push(parts);
        }
      }
      return partku;
    },
  },
};
</script>
<style>
  .btn-white{
    background-color: #fff!important;
    color: #A42126 !important;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .v-menu__content{
    margin-top: 10px;
    border-radius:0px !important;
  }
  .box-soal{
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05)!important;
  }
  #panels .v-expansion-panel::before{
    box-shadow: none!important;
  }
  #tabs .v-tabs-bar{
    height: 32px!important;
  }
  #customTab .v-tab{
    padding: 1px!important;
    margin-right:20px;
    min-width: max-content!important;
    max-width: max-content!important;
  }
  #customTab .v-tabs-slider {
    width: 100%!important;
  }
  #hideDetail .v-text-field.v-text-field--enclosed .v-text-field__details{
    display: none;
  }
</style>
