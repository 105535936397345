<template>
  <body style="width: 100%">
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-row v-else>
      <section
        class="head text-center"
        style="width: 100%; font-family: arial; margin-bottom: 15px"
      >
        <br />
        <table style="width: 70%; margin: auto">
          <tr style="padding-bottom: 15px">
            <td style="width: 5%; text-align: center">
              <img src="@/assets/images/DEL_logo.png"  width="130"/>
            </td>
            <td style="width: 86%; text-align: center">
              <!-- <p style="font-size: 15px; font-weight: 400">
                LEMBAGA KURSUS DAN PELATIHAN
              </p> -->
              <span style="font-size: 36px; font-weight: 700">
                Discovery English
              </span>
              <br>
              <span style="font-size: 15px; font-weight: 400">
                CV. Davendra Edukasi Jaya
              </span>
              <br />
              <span style="font-size: 12px; font-weight: 400">
                Nomon Induk Berusaha 1228000610399
              </span>
            </td>
            <td style="width: 15%"></td>
          </tr>
          <tr>
            <td style="width: 5%; text-align: center"></td>
            <td style="width: 86%; text-align: center; padding-top: 15px">
              <p style="font-size: 15px; font-weight: 700">
                CERTIFICATE OF ACHIEVEMENT
              </p>

              <p style="font-size: 10px; font-weight: 400">
                ID Certificate :
                <span class="text-uppercase">{{ data.order_no.replace('YEC', 'DE') }}</span>
              </p>
              <p></p>
              <table style="width:95%;font-size: 10px;font-weight: 400;margin-bottom: 30px;text-align:center;margin-left: auto;">
                <tr>
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Name </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;"> {{ data.member ? data.member.fullname : "" }}</td>
                </tr>
                <tr style="background: #fff;">
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Date of Birth </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;">{{
                    date_format(data.member ? data.member.date_of_birth : "")
                  }}</td>
                </tr>
                <tr>
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Test Date </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;">{{ date_format(data.start_test) }}</td>
                </tr>
              </table>
              <p style="font-size: 10px; font-weight: 400; margin-bottom: 15px">
                has taken <b>TOEFL-Like</b> and achieved
                the following scores
              </p>
              <table
                style="
                  width: 58%;
                  font-size: 10px;
                  font-weight: 400;
                  margin-bottom: 10px;
                  text-align: right;
                "
              >
                <tr>
                  <td style="padding: 1px">Reading</td>
                  <td style="padding: 1px">:</td>
                  <td style="padding: 1px; text-align: left">
                    {{ data.section ? data.section.reading.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px">Structure</td>
                  <td style="padding: 1px">:</td>
                  <td style="padding: 1px; text-align: left">
                    {{ data.section ? data.section.structure.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px">Listening</td>
                  <td style="padding: 1px">:</td>
                  <td style="padding: 1px; text-align: left">
                    {{ data.section ? data.section.listening.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px"><b>Total Correct Point</b></td>
                  <td style="padding: 1px">:</td>
                  <td style="padding: 1px; text-align: left">{{ correct }}</td>
                </tr>
                <tr>
                  <td style="padding: 1px">
                    <b>Your Prediction Score for TOEFL</b>
                  </td>
                  <td style="padding: 1px">:</td>
                  <td style="padding: 1px; text-align: left">
                    {{ data.score }}
                  </td>
                </tr>
              </table>
              <p style="font-size: 10px; font-weight: 700; text-align: center">
                Proficiency Level : {{ data.level }}
              </p>
            </td>
            <td style="width: 15%"></td>
          </tr>
        </table>
      </section>
      <br />
      <section class="foot" style="width: 100%; font-family: arial">
        <table style="width: 70%; margin: auto">
          <tr>
            <td style="width: 15%; text-align: center">
              <p style="font-size: 6px; font-weight: 400">
                ID Certificate:
                <span class="text-uppercase">{{ data.order_no.replace('YEC', 'DE')}}</span>
              </p>
              <p class="mb-2">
                <vue-qrcode
                  :value="`https://www.yec.co.id/review-sertifikat-epot/?id=${data.license}`"
                  errorCorrectionLevel="H"
                  :width="100"
                  :margin="0"
                  :color="{ dark: '#4c6583' }"
                />
              </p>
              <p style="font-size: 6px; font-weight: 400">
                scan me to verify this certificate
              </p>
            </td>
            <td style="width: 70%; padding-left: 16px">
              <p style="font-size: 10px; font-weight: 400">
                This certificate is acceptable until
                <b>{{ expired }}</b>
              </p>
              <p
                style="font-size: 10px; font-weight: 400; line-height: 0.25rem"
              >
                TOEFL is a registered trademark of Educational Testing Service
              </p>
              <p
                style="font-size: 10px; font-weight: 400; line-height: 0.25rem"
              >
                (ETS), This document is not endorsed or approved by ETS
              </p>
            </td>
            <td style="width: 30%">
              <img src="@/assets/images/DEL_logo_ttd_dir.png" width="230" />
            </td>
          </tr>
        </table>
      </section>
      <section
        class="next"
        style="width: 100%; font-family: arial; page-break-before: always"
      >
        <hr />
        <div style="width: 70%; margin: auto">
          <p style="font-size: 18px; font-weight: 700; margin-top: 30px">
            Certificate Verification
          </p>
          <div style="width: 50%; margin-bottom: 10px">
            <p
              style="
                font-size: 10px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Each certificate has unique QR code. Please scan the QR Code by
              using QR Code scanner application and click the link appeared
              after scanning. The link will lead you to a website page that
              presents the exact information as written on the certificate.If
              the information on this certificate is not the same as on the
              website page; especially the name and the score; this certificate
              is considered as counterfeit or not valid.
            </p>
            <p style="font-size: 18px; font-weight: 700">Proficiency Level</p>
            <table style="border-collapse: collapse; width: 100%">
              <tr>
                <th
                  style="
                    border: 0.5px solid #00000080;
                    text-align: left;
                    padding: 8px;
                  "
                >
                  Score
                </th>
                <th
                  style="
                    border: 0.5px solid #00000080;
                    text-align: left;
                    padding: 8px;
                  "
                >
                  Proficiency Level
                </th>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  310 - 400
                </td>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  Beginner
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  403 - 460
                </td>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  Intermediate
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  463 - 520
                </td>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  Upper intermediate
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  523 - 677
                </td>
                <td style="border: 0.5px solid #00000080; padding: 8px">
                  Advanced
                </td>
              </tr>
            </table>
          </div>
          <p style="font-size: 10px; font-weight: 400; margin-bottom: 0px">
            Issued by Discovery English
          </p>
          <p style="font-size: 10px; font-weight: 400">toefldiscoveryenglish.com</p>
        </div>
      </section>
    </v-row>
  </body>
</template>
<script>
import VueQrcode from "vue-qrcode";

export default {
  name: "template-certificate",
  components: { VueQrcode },
  props: {
    loading: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },
  computed: {
    correct() {
      let section = this.data.section;
      return section
        ? `${
            section.listening.total_point +
            section.reading.total_point +
            section.structure.total_point
          }`
        : "";
    },
    expired() {
      let expired = new Date(this.data.end_test);
      expired.setFullYear(expired.getFullYear() + 1);
      return this.date_format(expired);
    },
  },
  methods: {
    date_format(raw_date) {
      let date = new Date(raw_date);

      return Date.parse(raw_date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getDate()}, ${date.getFullYear()}`
        : "";
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
</style>